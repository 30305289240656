// eslint-disable-next-line
export const isOpera = typeof window !== 'undefined' && ( (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0);

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  typeof window !== 'undefined' &&
  (/constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === '[object SafariRemoteNotification]';
      // eslint-disable-next-line
  })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)));

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ typeof document !== 'undefined' && !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && typeof window !== 'undefined' && !!window.StyleMedia;

// Chrome 1+
export const isChrome = typeof window !== 'undefined' && !!window.chrome && !!window.chrome.webstore;

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;

export const isIOS = typeof navigator !== 'undefined' && navigator.userAgent.indexOf(`Mac`) >= 0;
